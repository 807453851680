import { createTheme } from "@mui/material";
import { Colors } from "../consts/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.PRIMARY,
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: "40px",
      lineHeight: "40px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    caption: {
      fontSize: 16,
      lineHeight: "22px",
    },
    button: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
    },
  },
});

export default theme;
